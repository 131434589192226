import React, { useEffect, useState } from "react";
import successImage from "../Images/success-web.svg";
import failedImage from "../Images/faild-web.svg";
import { useNavigate } from "react-router-dom";
import { DotLoader, BeatLoader } from "react-spinners";
import Footer from "../Components/Footer";
import { makeAccept } from "../api";

function PaymentCheckMayser() {
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [currentOrderID, setOrderId] = useState(
    localStorage.getItem("tempOrderId")
  );

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const status = params.get("status");
    const transaction_id = params.get("id");
    

    if (status === "paid") {
      const data = {
        transactionId: transaction_id,
      };
      makeAccept(currentOrderID, localStorage.getItem("token"), data).then((res) => {
        // localStorage.removeItem("tempOrderId");
        setIsSuccess(true);
      });
      
    } else {
      
      setIsSuccess(false);
    }
    setLoading(false);
  }, []);

  function handleProceed() {
    navigate(`/order-track/${currentOrderID}`);
  }

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          style={{ marginTop: "5em", marginBottom: "5em" }}
          className="container orderSuccess"
        >
          <div className="shareForm">
            <img
              src={isSuccess ? successImage : failedImage}
              alt={isSuccess ? "Success" : "Failed"}
              style={{ marginBottom: "1em" }}
            />
            <p style={{ color: "rgb(99, 50, 53)" }}>
              {isSuccess ? "Payment Successful" : "Payment Failed"}
            </p>
            {isSuccess && (
              <button
                style={{ display: "inline-block" }}
                onClick={handleProceed}
              >
                Track Order
              </button>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default PaymentCheckMayser;
