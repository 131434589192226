import "../Styles/CartProcess.css";
import Footer from "../Components/Footer";
import { HiShoppingCart } from "react-icons/hi";
import { FaWallet } from "react-icons/fa";
import { Link } from "react-router-dom";
import OrderSummary from "../Components/OrderSummary";
import op from "../Images/opLogo.svg";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDoubleDown } from "react-icons/bs";
import { IoChatbubblesOutline } from "react-icons/io5";
import { RiMotorbikeFill } from "react-icons/ri";
import { MdOutlineEventNote } from "react-icons/md";
import ChangeType from "../Components/ChangeType";
import s1 from "../Images/OrderTrack/s1.svg";
import s1g from "../Images/OrderTrack/s1g.svg";
import s2 from "../Images/OrderTrack/s2.svg";
import s2g from "../Images/OrderTrack/s2g.svg";
import s3 from "../Images/OrderTrack/s3.svg";
import s3g from "../Images/OrderTrack/s3g.svg";
import s4 from "../Images/OrderTrack/s4.svg";
import s4g from "../Images/OrderTrack/s4g.svg";
import { useParams } from "react-router-dom";
import { order_track, pay_again, cancel_request, Clear_cart } from "../api";
import CancelReason from "../Components/CancelReason";
import { CustomerInfo } from "../api";
import { BeatLoader } from "react-spinners";
import { getAnalytics, logEvent } from "firebase/analytics";
import { AiFillCar } from "react-icons/ai";
import { repeat_order } from "../api";
import { app } from "../Firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import status1 from "../Images/status1.png";
import status1Active from "../Images/status1Active.png";
import status2 from "../Images/status2.png";
import status2Active from "../Images/status2Active.png";
import status3 from "../Images/status3.png";
import status3Active from "../Images/status3Active.png";
import status4 from "../Images/status4.png";
import status4Active from "../Images/status4Active.png";
import statusDots from "../Images/statusDots.png";
import CheckoutCard from "../Components/CheckoutCard";
import { branchDonationId } from "../constant";
import CarDialog from "../Components/chooseLocation";

function OrderTracking(props) {
  let history = useNavigate();
  const [nav, setNav] = useState(null);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [walletPay, setWalletPay] = useState(false);
  const [orderData, setOrderData] = useState();
  const [products, setProducts] = useState([]);
  const [moda, setModal] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [pay, setPay] = useState(false);
  const [phone, setPhone] = useState("");
  const analytics = getAnalytics(app);
  const [changeModal, setChangeModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [msgEn, setMsgEn] = useState();
  const [carAddressAdded, setCarAddressAdded] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const openDialogCar = () => {
    setDialogOpen(true); // Set the state to open the dialog
  };

  const closeDialogCar = () => {
    setDialogOpen(false);
  };

  //CustomerInfo api call to get user phone (for analytics and webengage purposes)
  //order_track api call (takes id from url using useParams) to get order data
  //paymentMethod is checked  to change screen view accordingly
  useEffect(() => {
    window.scrollTo(0, 0);

    const token = localStorage.getItem("token");
    let phone = "";
    CustomerInfo(token).then((response) => {
      setPhone(response.data.data.phone.toString());
      phone = response.data.data.phone.toString();
    });

    order_track(id, token).then((response) => {
      setOrderData(response.data.data);
      if (response.data.data.paymentMethod == "wallet") {
        setWalletPay(true);
      }

      setProducts(response.data.data.products);

      let arr = [];

      response.data.data.products.map((data) =>
        arr.push({
          name: data.sizeEnName,
          quantity: data.quantity,
        })
      );

      logEvent(analytics, "single_order", {
        phone: phone,
        products: JSON.stringify(arr),
      });

      /*
      window.webengage.track("Single Order", {
        phone: phone,
        products: response.data.data.products
      })
*/

      //for changing screen status
      if (response.data.data.statusId === 1) {
        //ACCEPTED
        setNav(0);
      } else if (response.data.data.statusId === 2) {
        //IN_KITCHEN
        setNav(1);
      } else if (response.data.data.statusId === 3) {
        //WITH_DRIVER
        setNav(2);
      } else if (response.data.data.statusId === 4) {
        //DELIVERED
        setNav(3);
      } else if (response.data.data.statusId === 6) {
        //WAITING_PAYMENT
        setNav(null);
      }

      setLoading(false);
    });
  }, [update, carAddressAdded]);

  //for WAITING PAYMENT orders
  //pay_again api call takes order id (from url)
  //user is then redirected to third party
  const payAgain = () => {
    pay_again(id, localStorage.getItem("token"))
      .then((response) => {
        localStorage.setItem("orderId", id);

        logEvent(analytics, "checkout_started", {
          phone: phone,
          amount: orderData.totalPrice,
          currency: orderData.enCurrency,
        });
        logEvent(analytics, "begin_checkout", null);
        /*
    window.webengage.track("Checkout Started", {
      phone: phone,
      amount: orderData.totalPrice,
      currency: orderData.enCurrency
    })*/

        localStorage.setItem("payment", "tap");
        window.location.href = response.data.data.url;
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");

          history("/login");
        }
      });
  };

  //opens modal for confirming cancellation (CancelReason component found in /Components)
  const cancelOrder = () => {
    setModal(true);
  };

  //repeat_order api call takes order id (from url)
  //user is then redirected to their cart
  function repeatOrder() {
    setLoading(true);
    if (props.cartItems !== 0) {
      setChangeModal(true);
      setLoading(false);
    } else {
      repeat_order(localStorage.getItem("token"), id)
        .then((response) => {
          localStorage.setItem("branchId", orderData.branchId);
          localStorage.setItem("typeId", orderData.typeId);
          if (parseInt(orderData.typeId) === 4) {
            localStorage.setItem("carmodel", orderData.carModel);
            localStorage.setItem("carColor", orderData.carColor);
            localStorage.setItem("carNumber", orderData.carNumber);
            localStorage.setItem("carNotes", orderData.carNotes);
          } else if (parseInt(orderData.typeId) === 2) {
            localStorage.setItem("addressId", orderData.address);
          }

          localStorage.setItem("process", 0);

          props.setCartChange(!props.cartChange); //called to  rerender useEffect to rerender the cart
          history("/cart");
        })
        .catch(function (err) {
          setLoading(false);
          if (err.response.status === 401) {
            //used to change cart icon's current value to 0

            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          } else {
            setMsgEn(`${err.response.data.error}`);
            setErrorMsgModel(true);
            setTimeout(function () {
              setErrorMsgModel(false);
            }, 2000);
          }
        });
    }
  }

  const goToInvoicePdf = (id) => {
    history(`/invoice/${id}-A`);
  };

  //on confirming clearing cart (from ChangeType modal)
  //user cart is cleared
  function handleClear() {
    setLoading(true);

    const token = window.localStorage.getItem("token");
    setChangeModal(false);

    Clear_cart(props.cartId, token)
      .then((response) => {
        localStorage.removeItem("promo"); //was used to store data that user entered in cart
        localStorage.removeItem("paymentMethod"); //was used to store data that user entered in cart
        //data stored in cartProcess2
        localStorage.removeItem("timeNow");
        localStorage.removeItem("cartMessage");
        localStorage.removeItem("orderDate");
        localStorage.removeItem("deliveryType");
        localStorage.removeItem("cardToken");

        localStorage.removeItem("offer");
        localStorage.setItem("process", 0); //was used to store user's current step in cart, set to 0 to begin steps from the start
        props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart
        repeat_order(localStorage.getItem("token"), id)
          .then((response) => {
            localStorage.setItem("branchId", orderData.branchId);
            localStorage.setItem("typeId", orderData.typeId);
            if (parseInt(orderData.typeId) === 4) {
              localStorage.setItem("carmodel", orderData.carModel);
              localStorage.setItem("carColor", orderData.carColor);
              localStorage.setItem("carNumber", orderData.carNumber);
              localStorage.setItem("carNotes", orderData.carNotes);
            } else if (parseInt(orderData.typeId) === 2) {
              localStorage.setItem("addressId", orderData.address);
            }

            localStorage.setItem("process", 0);

            props.setCartChange(!props.cartChange); //called to  rerender useEffect to rerender the cart
            history("/cart");
          })
          .catch(function (err) {
            setLoading(false);
            if (err.response.status === 401) {
              //used to change cart icon's current value to 0

              props.setCartItem(0);

              localStorage.removeItem("token");
              localStorage.removeItem("userPhone");
              localStorage.removeItem("userName");
              localStorage.removeItem("basket");
              //was used to store user's current step in cart process
              localStorage.removeItem("process");

              history("/login");
            }
          });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");

          history("/login");
        }
      });
  }
  const handleSucessCar = () => {
    setCarAddressAdded(true);
  };

  return (
    <>
      <div
        style={{ minHeight: "100px" }}
        className={errorMsgModel ? "addAlert " : " addAlert alert-hidden "}
      >
        <span className="errMsg">{errorMsgModel ? msgEn : null}</span>
      </div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="cartProcess">
          {isDialogOpen && (
            <CarDialog
              handleCancel={closeDialogCar}
              orderId={orderData.id}
              onSucess={handleSucessCar}
            />
          )}

          {moda ? (
            <CancelReason
              phone={phone}
              setCartItem={props.setCartItem}
              orderData={orderData}
              products={products}
              setModal={setModal}
            />
          ) : null}
          {changeModal ? (
            <ChangeType
              text="repeat"
              handleClear={handleClear}
              setModal={setChangeModal}
            />
          ) : null}

          {pay ? (
            <CheckoutCard
              countryCode={orderData.countryCode}
              update={update}
              setUpdate={setUpdate}
              setModal={setPay}
              id={id}
            />
          ) : null}
          {/*
          <div className="cpTop">

          <div className={nav==0 ||nav==1 ||nav==2 ||nav==3 ? "activeStep":null}>
<div  style={{zIndex:"3"}}  className={i18n.language=="en"?"step1 ":"step1Ar "} > <div>
  <img src={nav==0 ||nav==1 ||nav==2 ||nav==3 ? s1:s1g}/>
  <p>{t("process.recieve")} </p>
  </div></div>
</div>
 
  <BsChevronDoubleDown className='processDown' size={30} />
  <div className={nav==1 ||nav==2 ||nav==3 ? "activeStep":null}>
  <div style={{zIndex:"2"}} className={i18n.language=="en"?"step ":"stepAr"} > <div>
<img src={nav==1 ||nav==2 ||nav==3 ? s2:s2g}/>

  <p style={i18n.language=="en"?{lineHeight:"20px"}:null}>  {t("process.prepare")}</p>
  </div></div></div>
 {orderData.typeId==2? <> 
  <BsChevronDoubleDown className='processDown' size={30} />
  <div className={nav==2 ||nav==3 ? "activeStep":null}>
  <div   className={i18n.language=="en"?"step  ":"stepAr"}  > <div>
  <img  src={nav==2 ||nav==3 ? s3:s3g}/>
  <p >  {t("process.delivery")}</p>
  </div></div></div> </>: null}
  
  <BsChevronDoubleDown className='processDown' size={30} />
  <div className={nav==3 ? "activeStep":null}>
  <div  className={i18n.language=="en"?"step4 ":"step4Ar "}>
  <div>
  <img src= {nav==3 ? s4:s4g}/>
  <p>  {t("process.delivered")}</p>
  </div></div>

</div>
 

 </div>*/}
          {orderData && orderData.branchId !== branchDonationId && (
            <>
              <div
                className={
                  i18n.language == "ar" ? "statusDiv" : "statusDiv statusDivEn"
                }
              >
                <div className="statusSquare">
                  <div
                    style={
                      nav == 0 || nav == 1 || nav == 2 || nav == 3
                        ? { backgroundImage: `url(${status1Active})` }
                        : { backgroundImage: `url(${status1})` }
                    }
                  ></div>
                  <p>{t("process.recieve")} </p>
                </div>
                <div className="statusDots">
                  <div style={{ backgroundImage: `url(${statusDots})` }}></div>
                </div>
                <div className="statusSquare">
                  <div
                    style={
                      nav == 1 || nav == 2 || nav == 3
                        ? { backgroundImage: `url(${status2Active})` }
                        : { backgroundImage: `url(${status2})` }
                    }
                  ></div>
                  <p>
                    {orderData.typeId == 2
                      ? t("process.prepare")
                      : t("process.order_InProgressBranch")}{" "}
                  </p>
                </div>
                <div className="statusDots">
                  <div style={{ backgroundImage: `url(${statusDots})` }}></div>
                </div>
                {orderData.typeId == 2 ? (
                  <>
                    <div className="statusSquare">
                      <div
                        style={
                          nav == 2 || nav == 3
                            ? { backgroundImage: `url(${status3Active})` }
                            : { backgroundImage: `url(${status3})` }
                        }
                      ></div>
                      <p>{t("process.delivery")} </p>
                    </div>

                    <div className="statusDots">
                      <div
                        style={{ backgroundImage: `url(${statusDots})` }}
                      ></div>
                    </div>
                  </>
                ) : null}

                <div className="statusSquare">
                  <div
                    style={
                      nav == 3
                        ? { backgroundImage: `url(${status4Active})` }
                        : { backgroundImage: `url(${status4})` }
                    }
                  ></div>
                  <p>
                    {orderData.typeId == 2
                      ? t("process.delivered")
                      : t("process.done_orderBranch")}{" "}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="container-fluid fluid">
            <div className="order-track">
              <div>
                <div className="ot-top">
                  <p> {t("process.details")}</p>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>

                  {(orderData.typeId ==4) && (!orderData.isArrived ) && (
                    <button style={{'marginTop': '1.3vh',}} onClick={openDialogCar}>
                      
                      {t("customerArrived")}
                    </button>
                  )}

                  {orderData.statusId == 6 ? (
                    <button
                      onClick={() => {
                        orderData.paymentOnlineType == "checkout"
                          ? setPay(true)
                          : payAgain();
                      }}
                    >
                    
                      {t("process.pay_again")}{" "}
                    </button>
                  ) : null}

                  {orderData.isOrderCancelable && orderData.editOrder == 1 ? (
                    <button
                      style={{
                        marginTop: "1.3vh",
                        
                      }}
                      onClick={cancelOrder}
                    >
                      {" "}
                      {t("process.cancel_request")}{" "}
                    </button>
                  ) : null}

                  {orderData.statusId == 4 ? (
                    <button
                      style={{
                        marginTop: "1.1vh",
                        [i18n.language === "ar" ? "marginLeft" : "marginRight"]:
                          "3vw",
                      }}
                      onClick={repeatOrder}
                    >
                      {" "}
                      {t("process.repeat_order")}{" "}
                    </button>
                  ) : null}

                  {orderData.statusId == 3 ? (
                    <button style={{ padding: "0", display: "unset" }}>
                      <a
                        className="callDriver"
                        href={`tel:${orderData.driverPhone}`}
                      >
                        {" "}
                        {t("process.call_driver")}{" "}
                      </a>{" "}
                    </button>
                  ) : null}

                  <button
                    style={{
                      position: "relative",
                      top: "1.3vh",
                      right: i18n.language == "en" ? "3vw" : undefined,
                      left: i18n.language == "ar" ? "3vw" : undefined,
                    }}
                    onClick={() => goToInvoicePdf(orderData.id)}
                  >
                    {" "}
                    {t("process.showInovice")}{" "}
                  </button>

                  {/*<button> <span><IoChatbubblesOutline size={25}/></span>   {t("process.contact")} </button>

 */}
                </div>

                <div className="mobileButtons row">
                  <div className="col-sm-4">
                    {orderData.statusId == 6 ? (
                      <button onClick={payAgain}>
                        {" "}
                        {t("process.pay_again")}{" "}
                      </button>
                    ) : null}
                  </div>
                  <div className="col-sm-4">
                    {orderData.isOrderCancelable && orderData.editOrder == 1 ? (
                      <button onClick={cancelOrder}>
                        {" "}
                        {t("process.cancel_request")}{" "}
                      </button>
                    ) : null}
                  </div>

                  <div className="col-sm-4">
                    {orderData.statusId == 4 ? (
                      <button onClick={repeatOrder}>
                        {t("process.repeat_order")}{" "}
                      </button>
                    ) : null}
                  </div>

                  <div className="col-sm-4">
                    {orderData.statusId == 3 ? (
                      <a href={`tel:${orderData.driverPhone}`}>
                        <button>{t("process.call_driver")} </button>{" "}
                      </a>
                    ) : null}
                  </div>
                  {/*
<div className="col-sm-4">
<button> <span><IoChatbubblesOutline size={25}/></span>   {t("process.contact")} </button>
</div>*/}
                </div>
                <div className="row ot-div1" style={{ marginTop: "1em" }}>
                  <div className="col-md-2 col-sm-3 col-4 ot-icon">
                    <div
                      style={
                        orderData.now == true
                          ? { backgroundImage: `url(${op})`, height: "5em" }
                          : { backgroundImage: `url(${op})` }
                      }
                    ></div>
                  </div>
                  <div className="col-md-10 col-sm-9 col-8">
                    <div className="row ot-details">
                      <div className="col-sm-6">
                        <p>
                          {t("process.id")}
                          <span>{orderData.id}</span>
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p>
                          {t("process.type")}{" "}
                          <span>
                            {orderData.typeId == 1
                              ? t("home.option-branch")
                              : orderData.typeId == 2
                              ? t("home.option-home")
                              : orderData.typeId == 3
                              ? t("home.option-donation")
                              : t("home.option-car")}
                          </span>
                        </p>
                      </div>

                      {orderData.now == false ? (
                        <div className="col-sm-6">
                          <p>
                            {t("process.date")} <span>{orderData.date}</span>
                          </p>
                        </div>
                      ) : null}

                      {orderData.now == false ? (
                        <div className="col-sm-6">
                          <p>
                            {t("process.time")}
                            <span>{orderData.time}</span>
                          </p>
                        </div>
                      ) : null}

                      <div className="col-12">
                        <p>
                          {t("process.creation")}
                          <span>{orderData.createdAt}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {orderData.typeId == 2 ? (
                <div className="ot-address">
                  <p className="ot-addressTitle">
                    {" "}
                    <RiMotorbikeFill
                      className={i18n.language == "ar" ? "iconDirection" : null}
                      size={25}
                    />
                    <span> {t("process.address")}</span>
                  </p>
                  <div className="row ot-addressDetail">
                    <div className="col-sm-6">
                      <p>{orderData.address}</p>
                    </div>
                    {/*
<div className="col-sm-6">
<p>16 شارع المشير</p>
</div>



<div className="col-sm-6">
<p>مدينة الرياض </p>
</div>
<div className="col-sm-6">
<p>{t("process.phone")}<span> 0122558774155</span></p>
</div>


 */}
                  </div>
                </div>
              ) : null}

              {orderData.typeId == 4 ? (
                <div className="ot-address">
                  <p className="ot-addressTitle">
                    {" "}
                    <AiFillCar
                      className={i18n.language == "ar" ? "iconDirection" : null}
                      size={25}
                    />
                    <span> {t("car.carDet")}</span>
                  </p>
                  <div className="row ot-addressDetail">
                    <div className="col-sm-6">
                      <p>
                        {t("car.carModel")}: {orderData.carModel}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        {t("car.carColor")}: {orderData.carColor}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        {t("car.carNumber")}: {orderData.carNumber}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {orderData.branchId === branchDonationId && (
                <div className="donation row ot-details">
                  <>
                    <p className="donation-title">
                      {t("home.donationDetails")}
                    </p>
                    <div className="col-sm-6">
                      <p>
                        {t("home.donerName")}
                        {" : "}
                        <span>{orderData.donatorName}</span>
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        {t("home.doner-name")}
                        {" : "}
                        <span>{orderData.donateForName}</span>
                      </p>
                    </div>

                    <div className="col-sm-12">
                      <p>
                        {t("home.donor-phone")}
                        {" : "}
                        <span>{orderData.donateForPhone}</span>
                      </p>
                    </div>
                  </>
                </div>
              )}
              <div className="ot-summary">
                <p className="ot-addressTitle">
                  <MdOutlineEventNote size={25} />
                  <span> {t("process.summary")}</span>
                </p>

                {products &&
                  products.map((data, index) => (
                    <>
                      <OrderSummary
                        key={index}
                        currency={
                          i18n.language == "ar"
                            ? orderData.arCurrency
                            : orderData.enCurrency
                        }
                        mealId={data.id}
                        price={data.price}
                        name={
                          i18n.language == "ar"
                            ? data.productArName
                            : data.productEnName
                        }
                        image={data.image}
                        extras={data.extras}
                        additionals={data.additionals}
                        description={
                          i18n.language == "ar"
                            ? data.productArDescription
                            : data.productEnDescription
                        }
                        quantity={data.quantity}
                      />
                      <div className="rowMagin"></div>
                    </>
                  ))}
              </div>

              <div className="ot-total">
                <div className="ot-totalDiv">
                  <p> {t("profile.payment")}</p>
                  <p className="ot-value">
                    {orderData.paymentMethod == "cash"
                      ? t("mainMenu.arrivalPayment")
                      : orderData.paymentMethod == "tap" ||
                        orderData.paymentMethod == "checkout"
                      ? t("process.credit")
                      : t("process.wallet")}
                  </p>
                </div>
                <div className="ot-totalDiv">
                  <p> {t("process.price")}</p>
                  <p className="ot-value">
                    {orderData.originalPrice}{" "}
                    {i18n.language == "ar"
                      ? orderData.arCurrency
                      : orderData.enCurrency}
                  </p>
                </div>

                <div className="ot-totalDiv">
                  <p>
                    {t("process.tax")} &nbsp;({orderData.tax}%){" "}
                  </p>

                  <p className="ot-value">
                    {orderData.taxValue}{" "}
                    {i18n.language == "ar"
                      ? orderData.arCurrency
                      : orderData.enCurrency}
                  </p>
                </div>
                {orderData.typeId == 2 ? (
                  <div className="ot-totalDiv">
                    <p> {t("process.delivery_charge")} </p>
                    <p className="ot-value">
                      {orderData.deliveryPrice}{" "}
                      {i18n.language == "ar"
                        ? orderData.arCurrency
                        : orderData.enCurrency}
                    </p>
                  </div>
                ) : null}
                {orderData.discount !== 0 ? (
                  <div className="ot-totalDiv">
                    <p> {t("process.discount")} </p>
                    <p className="ot-value">
                      {orderData.discount}{" "}
                      {i18n.language == "ar"
                        ? orderData.arCurrency
                        : orderData.enCurrency}
                    </p>
                  </div>
                ) : null}
                {walletPay ? (
                  <>
                    <div className="ot-totalDiv">
                      <p> {t("process.walletPay")} </p>
                      <p className="ot-value">
                        {orderData.walletPay}{" "}
                        {i18n.language == "ar"
                          ? orderData.arCurrency
                          : orderData.enCurrency}
                      </p>
                    </div>
                    <div className="ot-totalDiv">
                      <p> {t("process.total")} </p>
                      <p className="ot-value">
                        {orderData.totalPrice}{" "}
                        {i18n.language == "ar"
                          ? orderData.arCurrency
                          : orderData.enCurrency}
                      </p>
                    </div>
                    <div style={{ transform: "unset" }} className="cpTotaldiv">
                      <p className="cpTotalP"> {t("process.remainPrice")}</p>
                      <div>
                        <p className="cpTotal">
                          {orderData.remainPrice == ""
                            ? 0
                            : orderData.remainPrice}
                        </p>
                        <p className="cpCurrency">
                          {i18n.language == "ar"
                            ? orderData.arCurrency
                            : orderData.enCurrency}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ transform: "unset" }} className="cpTotaldiv">
                    <p className="cpTotalP"> {t("process.total")}</p>
                    <div>
                      <p className="cpTotal">{orderData.totalPrice}</p>
                      <p className="cpCurrency">
                        {i18n.language == "ar"
                          ? orderData.arCurrency
                          : orderData.enCurrency}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}

export default OrderTracking;
