import "../Styles/CartProcess.css";
import "../Styles/Checkout.css";

import { useState, useEffect, useRef } from "react";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import "../Styles/Login.css";

import { IoWalletOutline } from "react-icons/io5";
import { BsCash } from "react-icons/bs";
import { BsCreditCard } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {
  Get_Basket,
  Get_Wallet,
  payment_methods,
  check_offer,
  create_order,
  CustomerInfo,
} from "../api";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { BeatLoader } from "react-spinners";
import { app } from "../Firebase/firebaseConfig";
import { TiTick } from "react-icons/ti";
import DiscountModal from "../Components/DiscountModal";
import FreeMealModal from "../Components/FreeMealModal";
import MoyasarPaymentForm from "../Components/mayser";
function CartProcess3(props) {
  const [active, setActive] = useState(0);
  const analytics = getAnalytics(app);
  const { t, i18n } = useTranslation();
  const [cart, setCArt] = useState();
  const history = useNavigate();
  const [deliveryPrice, setDeliveryPrice] = useState();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [wallet, setWallet] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [onlineType, setOnlineType] = useState();

  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [msgEn2, setMsgEn2] = useState();
  const [msgAr2, setMsgAr2] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [errorMsgModel2, setErrorMsgModel2] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("paymentMethod")
      ? localStorage.getItem("paymentMethod")
      : null
  );

  const [createBool, setCreateBool] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [freeMeal, setFreeMeal] = useState(false);
  const [offer, setOffer] = useState(
    localStorage.getItem("offer") ? localStorage.getItem("offer") : false
  );
  const [phone, setPhone] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [breakdown, setBreakdown] = useState();
  const [cardValid, setCardValid] = useState(false);

  const [code, setCode] = useState(
    localStorage.getItem("promo") ? localStorage.getItem("promo") : ""
  );
  const [currency, setCurrency] = useState({
    ar: localStorage.getItem("currencyAr"),
    en: localStorage.getItem("currencyEn"),
  });

  const codeRef = useRef("");

  const walletRef = useRef();
  const cashRef = useRef();
  const tapRef = useRef();
  //fetch customer data for analytics and webengage use
  //Get_Bakset api call fetch cart data
  //payment_methods api call (takes cart id ,token and deliveryType: ordinary/fast) fetch cart payment options, receipt and user wallet balance

  useEffect(() => {
    const token = localStorage.getItem("token");
    localStorage.removeItem("cardToken");

    const delivery = localStorage.getItem("order_details"); //from CartProcess1

    CustomerInfo(token).then((response) => {
      setPhone(response.data.data.phone.toString());
    });
    setDeliveryPrice(JSON.parse(delivery).delivery);

    Get_Basket(token)
      .then((response) => {
        setCArt(response.data.data.cart);

        payment_methods(
          localStorage.getItem("CartId"),
          localStorage.getItem("typeId") == 2
            ? localStorage.getItem("deliveryType")
            : " ",
          token
        ).then((response) => {
          setBreakdown(response.data.data);
          setPaymentMethod(response.data.data.paymentMethods);
          setOnlineType(
            response.data.data.paymentMethods.paymentOnlineType
              ? response.data.data.paymentMethods.paymentOnlineType
              : "tap"
          );
          /*   if( localStorage.getItem("paymentMethod")){ //setting default chosen payment option
        setSelectedOption(localStorage.getItem("paymentMethod"))}
        else{

          setSelectedOption(response.data.data.paymentMethods.wallet? "wallet":"cash")
        }*/

          setWallet(parseFloat(response.data.data.walletBalance).toFixed(2));
          setLoading(false);
        });
      })
      .catch((error) => {
        localStorage.setItem("process", 0);
        props.setNav(0);
      });
  }, []);

  //check_offer api call takes (data) object
  //if there is a discount or free meals a modal is displayed
  function handleOffer() {
    setCreateBool(false);
    const detail = JSON.parse(localStorage.getItem("order_details")); //from CartProcess2

    const token = localStorage.getItem("token");
    setErrorMsgModel2(false);
    setOffer(false);
    localStorage.setItem("offer", false);
    // setCodeInvalid(false)

    if (selectedOption == null) {
      setErrorMsgModel(true);
      setMsgEn("Please choose payment method");
      setMsgAr("برجاء اختيار طريقة الدفع");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else {
      const data = {
        paymentMethod: selectedOption,
        code: codeRef.current.value,
        notes: "",
        date: detail.date,
        now: detail.now.toString(),
        time: detail.time,
      };

      check_offer(data, token)
        .then((response) => {
          //setCodeInvalid(false)

          if (response.data.data.isOffer == true) {
            if (codeRef.current.value !== "") {
              localStorage.setItem("showIcn", true);
            } else {
              localStorage.setItem("showIcn", false);
            }
          }

          if (
            response.data.data.offerType === "discount" &&
            response.data.data.freeMeals.length !== 0
          ) {
            window.localStorage.setItem("offerType", "freeMealsDiscount");

            if (codeRef.current.value !== "") {
              logEvent(analytics, "coupon_code_applied", {
                phone: phone,
                code: codeRef.current.value,
              });
            }
            window.localStorage.setItem(
              "FREEMEALS",
              JSON.stringify(response.data.data.freeMeals)
            );
            setDiscount(true);
            setOffer(true);
            localStorage.setItem("offer", true);
            localStorage.setItem("totalPrice", breakdown.totalPrice);

            localStorage.setItem(
              "discountPrice",
              response.data.data.totalPrice
            );
            localStorage.setItem("process", 2);
            localStorage.setItem("discAmount", response.data.data.discount);
          } else if (
            response.data.data.offerType === "cashBack" &&
            response.data.data.freeMeals.length !== 0
          ) {
            window.localStorage.setItem("offerType", "freeMealsCashBack");
            window.localStorage.setItem(
              "FREEMEALS",
              JSON.stringify(response.data.data.freeMeals)
            );
            setDiscount(true);
            setOffer(true);
            localStorage.setItem("offer", true);
            localStorage.setItem("process", 2);

            if (codeRef.current.value !== "") {
              logEvent(analytics, "coupon_code_applied", {
                phone: phone,
                code: codeRef.current.value,
              });
            }
          } else if (
            response.data.data.offerType === "discount" &&
            response.data.data.cashBack == true
          ) {
            setDiscount(true);
            setOffer(true);
            localStorage.setItem("offer", true);
            if (codeRef.current.value !== "") {
              logEvent(analytics, "coupon_code_applied", {
                phone: phone,
                code: codeRef.current.value,
              });

              //   window.webengage.track("Coupon Code Applied", {
              //  phone: phone,
              // code:codeRef.current.value
              //  })
            }

            localStorage.setItem("totalPrice", breakdown.totalPrice);

            localStorage.setItem(
              "discountPrice",
              response.data.data.totalPrice
            );
            localStorage.setItem("process", 2);
            localStorage.setItem("discAmount", response.data.data.discount);

            window.localStorage.setItem("offerType", "discountCashBack");
          } else if (response.data.data.offerType === "freeMeals") {
            window.localStorage.setItem("offerType", "freeMeals");

            if (codeRef.current.value !== "") {
              logEvent(analytics, "coupon_code_applied", {
                phone: phone,
                code: codeRef.current.value,
              });

              //   window.webengage.track("Coupon Code Applied", {
              //   phone: phone,
              //   code:codeRef.current.value
              //  })
            }

            window.localStorage.setItem(
              "FREEMEALS",
              JSON.stringify(response.data.data.freeMeals)
            );

            localStorage.setItem("process", 2);
            setFreeMeal(true);
            setOffer(true);
            localStorage.setItem("offer", true);
            //    history('/free-meals');
          } else if (response.data.data.offerType === "discount") {
            setDiscount(true);
            setOffer(true);
            localStorage.setItem("offer", true);
            if (codeRef.current.value !== "") {
              logEvent(analytics, "coupon_code_applied", {
                phone: phone,
                code: codeRef.current.value,
              });

              //   window.webengage.track("Coupon Code Applied", {
              //  phone: phone,
              // code:codeRef.current.value
              //  })
            }

            localStorage.setItem("totalPrice", breakdown.totalPrice);

            localStorage.setItem(
              "discountPrice",
              response.data.data.totalPrice
            );
            localStorage.setItem("process", 2);
            localStorage.setItem("discAmount", response.data.data.discount);

            window.localStorage.setItem("offerType", "discount");
            // history("/discount")
          } else if (response.data.data.offerType === "cashBack") {
            setDiscount(true);
            setOffer(true);
            localStorage.setItem("offer", true);
            if (codeRef.current.value !== "") {
              logEvent(analytics, "coupon_code_applied", {
                phone: phone,
                code: codeRef.current.value,
              });

              //   window.webengage.track("Coupon Code Applied", {
              //   phone: phone,
              //   code:codeRef.current.value
              //  })
            }

            window.localStorage.setItem("offerType", "cashBack");

            localStorage.setItem("process", 2);
            // history('/discount');
          } else {
            setErrorMsgModel2(true);
            setMsgAr2("الرمز الترويجي غير صحيح");
            setMsgEn2("Promo code is invalid");
            setSubmitLoading(false);
          }
        })
        .catch((error) => {
          console.log(detail);

          //setDiscount(false)
          if (error.response.status === 401) {
            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          } else if (error.response.data.errorType == "code") {
            //setCodeInvalid(true)

            if (
              error.response.data.error ===
              "طلبك غير مستوفي لشروط الرمز الترويجي"
            ) {
              setMsgEn2("طلبك غير مستوفي لشروط الرمز الترويجي");
              setMsgAr2("طلبك غير مستوفي لشروط الرمز الترويجي");
              setErrorMsgModel2(true);
              setSubmitLoading(false);
            } else if (
              error.response.data.error === "الرمز الترويجي غير صحيح"
            ) {
              setMsgAr2("الرمز الترويجي غير صحيح");
              setMsgEn2("Promo code is invalid");
              setErrorMsgModel2(true);
              setSubmitLoading(false);
            } else if (
              error.response.data.error ===
              "الرمز الترويجي غير متاح في هذا الفرع"
            ) {
              setMsgAr2("الرمز الترويجي غير متاح في هذا الفرع");
              setMsgEn2("Promo code is unavailable in this branch");
              setErrorMsgModel2(true);
              setSubmitLoading(false);
            }
          } else {
            getError(error.response.data.error);
          }
        });
    }
  }

  async function checkOffer(cardToken) {
    const detail = JSON.parse(localStorage.getItem("order_details")); //from CartProcess2

    const token = localStorage.getItem("token");

    if (
      selectedOption == "checkout" ||
      (selectedOption == "wallet" &&
        (offer &&
        (localStorage.getItem("offerType") == "discount" ||
          localStorage.getItem("offerType") == "freeMealsDiscount" ||
          localStorage.getItem("offerType") == "discountCashBack")
          ? wallet < localStorage.getItem("discountPrice")
          : wallet < breakdown.totalPrice) &&
        onlineType == "checkout")
    ) {
      localStorage.setItem("cardToken", cardToken);
    }
    setSubmitLoading(true);
    if (selectedOption == null) {
      setErrorMsgModel(true);
      setMsgEn("Please choose payment method");
      setMsgAr("برجاء اختيار طريقة الدفع");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else {
      const data = {
        paymentMethod: selectedOption,
        code: codeRef.current.value,
        notes: "",
        date: detail.date,
        now: detail.now.toString(),
        time: detail.time,
      };

      check_offer(data, token)
        .then((response) => {
          //setCodeInvalid(false)

          setCreateBool(true);
          localStorage.setItem("showIcn", false);

          if (codeRef.current.value !== "") {
            createOrder(cardToken);
          } else {
            if (
              response.data.data.offerType === "discount" &&
              response.data.data.freeMeals.length !== 0
            ) {
              window.localStorage.setItem("offerType", "freeMealsDiscount");

              if (codeRef.current.value !== "") {
                logEvent(analytics, "coupon_code_applied", {
                  phone: phone,
                  code: codeRef.current.value,
                });
              }
              window.localStorage.setItem(
                "FREEMEALS",
                JSON.stringify(response.data.data.freeMeals)
              );
              setDiscount(true);
              setOffer(true);
              localStorage.setItem("offer", true);
              localStorage.setItem("totalPrice", breakdown.totalPrice);

              localStorage.setItem(
                "discountPrice",
                response.data.data.totalPrice
              );
              localStorage.setItem("process", 2);
              localStorage.setItem("discAmount", response.data.data.discount);
            } else if (
              response.data.data.offerType === "cashBack" &&
              response.data.data.freeMeals.length !== 0
            ) {
              window.localStorage.setItem("offerType", "freeMealsCashBack");
              window.localStorage.setItem(
                "FREEMEALS",
                JSON.stringify(response.data.data.freeMeals)
              );
              setDiscount(true);
              setOffer(true);
              localStorage.setItem("offer", true);
              localStorage.setItem("process", 2);

              if (codeRef.current.value !== "") {
                logEvent(analytics, "coupon_code_applied", {
                  phone: phone,
                  code: codeRef.current.value,
                });
              }
            } else if (
              response.data.data.offerType === "discount" &&
              response.data.data.cashBack == true
            ) {
              setDiscount(true);
              setOffer(true);
              localStorage.setItem("offer", true);
              if (codeRef.current.value !== "") {
                logEvent(analytics, "coupon_code_applied", {
                  phone: phone,
                  code: codeRef.current.value,
                });

                //   window.webengage.track("Coupon Code Applied", {
                //  phone: phone,
                // code:codeRef.current.value
                //  })
              }

              localStorage.setItem("totalPrice", breakdown.totalPrice);

              localStorage.setItem(
                "discountPrice",
                response.data.data.totalPrice
              );
              localStorage.setItem("process", 2);
              localStorage.setItem("discAmount", response.data.data.discount);

              window.localStorage.setItem("offerType", "discountCashBack");
            } else if (response.data.data.offerType === "freeMeals") {
              window.localStorage.setItem("offerType", "freeMeals");

              if (codeRef.current.value !== "") {
                logEvent(analytics, "coupon_code_applied", {
                  phone: phone,
                  code: codeRef.current.value,
                });

                //   window.webengage.track("Coupon Code Applied", {
                //   phone: phone,
                //   code:codeRef.current.value
                //  })
              }

              window.localStorage.setItem(
                "FREEMEALS",
                JSON.stringify(response.data.data.freeMeals)
              );

              setFreeMeal(true);
              setOffer(true);

              localStorage.setItem("offer", true);
              //    history('/free-meals');
            } else if (response.data.data.offerType === "discount") {
              setDiscount(true);
              setOffer(true);

              localStorage.setItem("offer", true);
              if (codeRef.current.value !== "") {
                logEvent(analytics, "coupon_code_applied", {
                  phone: phone,
                  code: codeRef.current.value,
                });

                //   window.webengage.track("Coupon Code Applied", {
                //  phone: phone,
                // code:codeRef.current.value
                //  })
              }

              localStorage.setItem("totalPrice", breakdown.totalPrice);

              localStorage.setItem(
                "discountPrice",
                response.data.data.totalPrice
              );
              localStorage.setItem("process", 2);
              localStorage.setItem("discAmount", response.data.data.discount);

              window.localStorage.setItem("offerType", "discount");
              // history("/discount")
            } else if (response.data.data.offerType === "cashBack") {
              setDiscount(true);
              setOffer(true);

              localStorage.setItem("offer", true);
              if (codeRef.current.value !== "") {
                logEvent(analytics, "coupon_code_applied", {
                  phone: phone,
                  code: codeRef.current.value,
                });

                //   window.webengage.track("Coupon Code Applied", {
                //   phone: phone,
                //   code:codeRef.current.value
                //  })
              }

              window.localStorage.setItem("offerType", "cashBack");

              localStorage.setItem("process", 2);
              // history('/discount');
            } else {
              createOrder(cardToken);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          //setDiscount(false)
          if (error.response && error.response.status === 401) {
            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          } else {
            getError(error.response && error.response.data.error);
          }
        });
    }
    return 1;
  }

  //on confirming order
  /* check_offer api call takes selected payment method and promo code
condition 1 (no offer or free meals): create_order api is called taking data2 as body (data2 value changes according to order type)
condition 2 (isOffer == true): price before and after discount are stored and user is redirected to /discount (Discount component found in /Pages)
condition 3 (there are freeMeals): the free meals data are stored and user is redirected to /free-meals (FreeMeals component found in /Pages)
*/
  //in condition 1 if payment method is tap user is redirected to third party service else, user us taken to 4th step in cart process CartProcess4

  function createOrder(cardToken) {
    setDiscount(false);
    setFreeMeal(false);
    const token = localStorage.getItem("token");
    const detail = JSON.parse(localStorage.getItem("order_details")); //from CartProcess2
    setSubmitLoading(true);

    if (selectedOption == null) {
      setErrorMsgModel(true);
      setMsgEn("Please choose payment method");
      setMsgAr("برجاء اختيار طريقة الدفع");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (
      localStorage.getItem("accountType") == "Company User" &&
      (!localStorage.getItem("organizationId") ||
        localStorage.getItem("organizationId") == "null")
    ) {
      setErrorMsgModel(true);
      setMsgEn(
        "Please select the organization for which you would like to issue a tax invoice"
      );
      setMsgAr("يرجي اختيار الشركة المراد اصدار فاتورة ضريبية لها ");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 3000);
    } else {
      const data = {
        payment: selectedOption,
        code: codeRef.current.value,
      };

      //(data2 value changes according to order type)

      var data2 = "";

      if (parseInt(localStorage.getItem("typeId")) === 4) {
      
        data2 = {
          token:
            selectedOption == "checkout" ||
            (selectedOption == "wallet" &&
              (offer &&
              (localStorage.getItem("offerType") == "discount" ||
                localStorage.getItem("offerType") == "freeMealsDiscount" ||
                localStorage.getItem("offerType") == "discountCashBack")
                ? wallet < localStorage.getItem("discountPrice")
                : wallet < breakdown.totalPrice) &&
              onlineType == "checkout")
              ? cardToken
              : "",
          remainPaymentMethod:
            selectedOption == "wallet" &&
            (offer &&
            (localStorage.getItem("offerType") == "discount" ||
              localStorage.getItem("offerType") == "freeMealsDiscount" ||
              localStorage.getItem("offerType") == "discountCashBack")
              ? wallet < localStorage.getItem("discountPrice")
              : wallet < breakdown.totalPrice)
              ? onlineType
              : null,

          paymentMethod: onlineType == "moyasar" ? "moyasar" : selectedOption,
          code: codeRef.current.value,
          notes: "",
          date: "",
          now: detail.now.toString(),
          time: "",
         

          carOption: true,
          carModel: localStorage.getItem("carmodel"),
          carColor: localStorage.getItem("carColor"),
          carNumber: localStorage.getItem("carNumber"),
          carNotes: localStorage.getItem("carNotes"),
          userOrganizationId:
            localStorage.getItem("accountType") == "Company User"
              ? parseInt(localStorage.getItem("organizationId"))
              : null,
        };
      } else if (parseInt(localStorage.getItem("typeId")) === 2) {
        data2 = {
          token:
            selectedOption == "checkout" ||
            (selectedOption == "wallet" &&
              (offer &&
              (localStorage.getItem("offerType") == "discount" ||
                localStorage.getItem("offerType") == "freeMealsDiscount" ||
                localStorage.getItem("offerType") == "discountCashBack")
                ? wallet < localStorage.getItem("discountPrice")
                : wallet < breakdown.totalPrice) &&
              onlineType == "checkout")
              ? cardToken
              : "",
          remainPaymentMethod:
            selectedOption == "wallet" &&
            (offer &&
            (localStorage.getItem("offerType") == "discount" ||
              localStorage.getItem("offerType") == "freeMealsDiscount" ||
              localStorage.getItem("offerType") == "discountCashBack")
              ? wallet < localStorage.getItem("discountPrice")
              : wallet < breakdown.totalPrice)
              ? onlineType
              : null,

          paymentMethod: onlineType == "moyasar" ? "moyasar" : selectedOption,
          code: codeRef.current.value,
          notes: "",
          date: detail.date,
          now: detail.now.toString(),
          time: detail.time,
          addressId: parseInt(localStorage.getItem("addressId")),
          userOrganizationId:
            localStorage.getItem("accountType") == "Company User"
              ? parseInt(localStorage.getItem("organizationId"))
              : null,
        };
      } else if (parseInt(localStorage.getItem("typeId")) === 3) {
        const formData = JSON.parse(localStorage.getItem("formData"));

        data2 = {
          donatorName: formData.donorName,
          donateForName: formData.recipientName,
          donateForPhone: formData.recipientPhone,
          token:
            selectedOption == "checkout" ||
            (selectedOption == "wallet" &&
              (offer &&
              (localStorage.getItem("offerType") == "discount" ||
                localStorage.getItem("offerType") == "freeMealsDiscount" ||
                localStorage.getItem("offerType") == "discountCashBack")
                ? wallet < localStorage.getItem("discountPrice")
                : wallet < breakdown.totalPrice) &&
              onlineType == "checkout")
              ? cardToken
              : "",
          paymentMethod: onlineType == "moyasar" ? "moyasar" : selectedOption,
          platform: "web",
          notes: "",
          date: detail.date,
          now: detail.now.toString(),
          time: detail.time,
        };
      } else {
        data2 = {
          token:
            selectedOption == "checkout" ||
            (selectedOption == "wallet" &&
              (offer &&
              (localStorage.getItem("offerType") == "discount" ||
                localStorage.getItem("offerType") == "freeMealsDiscount" ||
                localStorage.getItem("offerType") == "discountCashBack")
                ? wallet < localStorage.getItem("discountPrice")
                : wallet < breakdown.totalPrice) &&
              onlineType == "checkout")
              ? cardToken
              : "",
          remainPaymentMethod:
            selectedOption == "wallet" &&
            (offer &&
            (localStorage.getItem("offerType") == "discount" ||
              localStorage.getItem("offerType") == "freeMealsDiscount" ||
              localStorage.getItem("offerType") == "discountCashBack")
              ? wallet < localStorage.getItem("discountPrice")
              : wallet < breakdown.totalPrice)
              ? onlineType
              : null,

          paymentMethod: onlineType == "moyasar" ? "moyasar" : selectedOption,
          code: codeRef.current.value,
          notes: "",
          date: detail.date,
          now: detail.now.toString(),
          time: detail.time,
          userOrganizationId:
            localStorage.getItem("accountType") == "Company User"
              ? parseInt(localStorage.getItem("organizationId"))
              : null,
        };
      }
      window.localStorage.setItem("ORDER", JSON.stringify(data2)); //for use in condition 2 & 3 when user is redirected to other screens /free-meals & /discount where the order will be created

      /* -- for use in analytics -- */
      let arr = [];

      props.meals.map((data) =>
        arr.push({
          name: data.size.enName,
          quantity: parseInt(data.quantity),
        })
      );

      let logData = {
        code: data.code,
        branch: localStorage.getItem("branchId"),
        carColor:
          parseInt(localStorage.getItem("typeId")) == 4 ? data2.carColor : " ",
        carModel:
          parseInt(localStorage.getItem("typeId")) == 4 ? data2.carModel : " ",
        carNotes:
          parseInt(localStorage.getItem("typeId")) == 4 ? data2.carNotes : " ",
        carNumber:
          parseInt(localStorage.getItem("typeId")) == 4 ? data2.carNumber : " ",
        carOption: parseInt(localStorage.getItem("typeId")) == 4 ? true : false,
        date: data2.date,
        notes: "",
        now: data2.now,
        paymentMethod: data2.paymentMethod,
        platform: "web",
        time: data2.time,
        deliveryType:
          localStorage.getItem("typeId") == 2
            ? localStorage.getItem("deliveryType")
            : " ",
        products: JSON.stringify(arr),
        type:
          localStorage.getItem("typeId") == 1
            ? "branch pick up"
            : localStorage.getItem("typeId") == 2
            ? "home delivery"
            : "car pick up",
        address:
          localStorage.getItem("typeId") == 2
            ? localStorage.getItem("addressEn")
            : " ",
        phone: phone,
      };

      localStorage.setItem("logData", JSON.stringify(logData)); //for use in analytic in FreeMeals and Discount components (found in /Pages)

      // for use in analytics
      console.log(data2);
      create_order(data2, token)
        .then((response) => {
          localStorage.setItem("tempOrderId", response.data.data.orderId);
          localStorage.setItem("transactionType", "order");

          logEvent(analytics, "create_order", logData);

          localStorage.removeItem("promo"); //remove value of promo code
          localStorage.removeItem("paymentMethod"); //remove value of chosen paymetn method

          localStorage.removeItem("process"); //remove user current step index in cart

          //data stored in cartProcess2
          localStorage.removeItem("timeNow");
          localStorage.removeItem("cartMessage");
          localStorage.removeItem("offer");

          localStorage.removeItem("orderDate");
          localStorage.removeItem("deliveryType");

          props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart

          if (parseInt(localStorage.getItem("typeId")) === 2) {
            //to be used in CartProcess4, home delivery has estimated delivery time
            localStorage.setItem(
              "timeEstimate",
              JSON.stringify(response.data.data.remainingTime)
            );
            localStorage.setItem("orderId", response.data.data.orderId);
          }

          if (parseInt(localStorage.getItem("typeId")) === 3) {
            localStorage.removeItem("formData");
          }
          // setProducts([]);

          if (selectedOption === "wallet") {
            localStorage.setItem("orderId", response.data.data.orderId); //to be used in PaymentSucess component fount in /Pages
            if (
              response.data.data.url == null ||
              response.data.data.url == undefined
            ) {
              logEvent(analytics, "purchase", {
                transaction_id: "",
              });

              props.setNav(3);
              localStorage.setItem("process", 3);
            } else {
              logEvent(analytics, "checkout_started", {
                phone: phone,
                amount: breakdown.totalPrice,
                currency: cart.currencyEn,
              });

              logEvent(analytics, "begin_checkout", null);
              /*
            window.webengage.track("Checkout Started", {
              phone: phone,
              amount: breakdown.totalPrice,
              currency: cart.currencyEn
            })*/
              localStorage.removeItem("cardToken");
              localStorage.setItem("checkPayment", "order");
              localStorage.setItem("currencyCode", cart.currencyEn);

              localStorage.setItem("payment", "tap"); //to be used in PaymentSucess component fount in /Pages

              window.location.href = response.data.data.url;
            }
          } else if (selectedOption === "tap") {
            localStorage.setItem("orderId", response.data.data.orderId); //to be used in PaymentSucess component fount in /Pages
            if (response.data.data.url === null) {
              console.log("tap");
            } else {
              logEvent(analytics, "checkout_started", {
                phone: phone,
                amount: breakdown.totalPrice,
                currency: cart.currencyEn,
              });

              logEvent(analytics, "begin_checkout", null);
              /*
      window.webengage.track("Checkout Started", {
        phone: phone,
        amount: breakdown.totalPrice,
        currency: cart.currencyEn
      })*/

              localStorage.setItem("payment", "tap"); //to be used in PaymentSucess component fount in /Pages

              window.location.href = response.data.data.url;
            }
          } else if (selectedOption === "checkout") {
            localStorage.setItem("orderId", response.data.data.orderId);
            localStorage.removeItem("cardToken");
            localStorage.setItem("checkPayment", "order");
            localStorage.setItem("currencyCode", cart.currencyEn);

            if (
              response.data.data.url === null ||
              response.data.data.url === "" ||
              response.data.data.url == undefined
            ) {
              props.setNav(3);
              localStorage.setItem("process", 3);
            } else {
              window.location.href = response.data.data.url;
            }
          } else {
            logEvent(analytics, "purchase", {
              transaction_id: "",
            });

            localStorage.setItem("orderId", response.data.data.orderId);
            props.setNav(3);
            localStorage.setItem("process", 3);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //used to change cart icon's current value to 0

            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          } else if (
            error.response.data.error ===
            "Transaction has been failed,please try again"
          ) {
            localStorage.setItem("failType", "order");
            localStorage.setItem("checkPayment", "order");
            localStorage.removeItem("cardToken");
            localStorage.setItem("orderId", error.response.data.data.orderId);
            console.log(error.response.data.data.orderId);
            setErrorMsgModel(true);
            setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
            setMsgEn("Transaction has been failed,please try again");
            setSubmitLoading(false);
            setTimeout(function () {
              props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart

              history("/payment-fail");
            }, 2000);
          } else {
            getError(error.response.data.error);
          }
        });
    }
  }

  const renderPayment = () => {
    if (onlineType == "checkout") {
      return (
        <div className="creditCard" style={{ marginTop: "1.5em" }}>
          <Frames
            cardValidationChanged={(e) => {
              setCardValid(e.isValid);
            }}
            config={{
              publicKey: `${process.env.REACT_APP_PAYMENT_METHOD_KEY}`,
              schemeChoice: true,
            }}
            cardTokenized={(e) => {
              checkOffer(e.token);
            }}
          >
            <CardNumber />
            <div className="date-and-code">
              <ExpiryDate />
              <Cvv />
            </div>

            {/* Or if you want to use single frames: */}
            {/* <CardFrame /> */}

            {submitLoading ? (
              <div
                style={{
                  height: "6vh",
                  marginTop: "2em",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                className="loader"
              >
                <BeatLoader
                  color={"#E47100"}
                  loading={submitLoading}
                  size={30}
                />
                <p className="paymentLoad">{t("payment.processing")}</p>
              </div>
            ) : (
              <button
                disabled={!cardValid}
                id="pay-button"
                onClick={() => {
                  setSubmitLoading(true);
                  if (localStorage.getItem("cardToken")) {
                    checkOffer(localStorage.getItem("cardToken"));
                  } else {
                    Frames.submitCard();
                  }
                }}
              >
                {t("process.confirm_order")}
              </button>
            )}
          </Frames>
        </div>
      );
    } else {
      return (
        <MoyasarPaymentForm
          amount={
            offer &&
            (localStorage.getItem("offerType") === "discount" ||
              localStorage.getItem("offerType") === "freeMealsDiscount" ||
              localStorage.getItem("offerType") === "discountCashBack")
              ? localStorage.getItem("discountPrice")
              : breakdown.totalPrice
          }
          onSubmit={async () => {
            await createOrder("moysar-"); // Call the external function
            // resolve will be triggered after checkOffer finishes
          }}
          codeRef={codeRef.current.value}
        />
      );
    }
  };

  //sets selected payment method
  const handleOptionChange = (changeEvent, n) => {
    console.log(changeEvent.target.value);
    localStorage.setItem("paymentMethod", changeEvent.target.value);
    setSelectedOption(changeEvent.target.value);
    setActive(n);
  };

  //called in checkOffer function
  //handles errors
  function getError(msg) {
    if (msg === "branch does not support branch pick up") {
      setErrorMsgModel(true);
      setMsgEn("branch not support branch pick up");
      setMsgAr("الفرع لا يدعم خدمة توصيل الطالبات");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not support delivery") {
      setErrorMsgModel(true);
      setMsgAr("الفرع لا يدعم خدمة توصيل المنزل");
      setMsgEn("branch not support delivery");
      setSubmitLoading(false);

      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not exist") {
      setErrorMsgModel(true);
      setMsgAr("الفرع خارج نطاق الخدمة");
      setMsgEn("branch not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not active") {
      setErrorMsgModel(true);
      setMsgAr("الفرع غير متاح حاليا");
      setMsgEn("branch not active");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
      setSubmitLoading(false);
    } else if (msg === "branch not open") {
      setErrorMsgModel(true);
      setMsgAr("الفرع مغلق الأن");
      setMsgEn("branch not open");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "meal not exist") {
      setErrorMsgModel(true);
      setMsgAr("الوجبة غير متاحة حاليا");
      setMsgEn("meal not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "meal not active") {
      setErrorMsgModel(true);

      setMsgAr("الوجبة غير متاحة حاليا");
      setMsgEn("meal not active");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "product not delivery") {
      setErrorMsgModel(true);
      setMsgAr("الوجبة غير متاحة للتوصيل");
      setMsgEn("product not delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "additional not delivery") {
      setErrorMsgModel(true);
      setMsgAr("الإضافات غير متاحة للتوصيل");
      setMsgEn("additional not delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "extra not exist") {
      setErrorMsgModel(true);
      setMsgAr("الزيادات غير متاحة");
      setMsgEn("extra not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "extra not belong to product") {
      setErrorMsgModel(true);
      setMsgAr("الزيادات غير متوفرة مع الوجبة");
      setMsgEn("extra not belong to product");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "The selected code is invalid.1") {
      setInvalid(true);

      logEvent(analytics, "coupon_code_failed", {
        phone: phone,
        code: codeRef.current.value,
      });
      /*
  window.webengage.track("Coupon Code Failed", {
    phone: phone,
    code:codeRef.current.value
  })*/

      setErrorMsgModel(true);
      setMsgAr("الكود غير صحيح");
      setMsgEn("The selected code is invalid.");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "الرمز الترويجي غير صحيح") {
      setInvalid(true);

      logEvent(analytics, "coupon_code_failed", {
        phone: phone,
        code: codeRef.current.value,
      });
      /*
  window.webengage.track("Coupon Code Failed", {
    phone: phone,
    code:codeRef.current.value
  })*/

      setErrorMsgModel(true);
      setMsgAr("الكود غير صحيح");
      setMsgEn("The selected code is invalid.");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "address not valid") {
      setErrorMsgModel(true);
      setMsgAr("العنوان غير صحيح");
      setMsgEn("address not valid");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "time not in branch time") {
      setErrorMsgModel(true);
      setMsgAr("الفرع مغلق في الوقت المحدد");
      setMsgEn("time not in branch time");

      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not support fast delivery") {
      setErrorMsgModel(true);
      setMsgAr("الفرع لا يوفر التوصيل السريع");
      setMsgEn("branch not support fast delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "Branch is busy right now,عفوًا الفرع مشغول الآن") {
      setErrorMsgModel(true);
      setMsgAr("الفرع مشغول، تقدر تطلب بعد الزحام.");
      setMsgEn(
        "This branch is currently busy. You can order for later when branch is not busy."
      );

      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not support ordinary delivery") {
      setErrorMsgModel(true);
      setMsgAr("الفرع لا يوفر التوصيل العادي");
      setMsgEn("branch not support ordinary delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "Transaction has been failed,please try again") {
      localStorage.setItem("failType", "order");
      localStorage.removeItem("cardToken");
      localStorage.setItem("checkPayment", "order");

      setErrorMsgModel(true);
      setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
      setMsgEn("Transaction has been failed,please try again");
      setSubmitLoading(false);
      setTimeout(function () {
        props.setCartChange(!props.cartChange);

        history("/payment-fail");
      }, 2000);
    } else if (msg === "طلبك غير مستوفي لشروط الرمز الترويجي") {
      setInvalid(true);

      logEvent(analytics, "coupon_code_failed", {
        phone: phone,
        code: codeRef.current.value,
      });
      /*
      window.webengage.track("Coupon Code Failed", {
        phone: phone,
        code:codeRef.current.value
      })
*/

      setErrorMsgModel(true);
      setMsgAr("طلبك غير مستوفي لشروط الرمز الترويجي");
      setMsgEn("Your order doesn't meet this promo code's conditions");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "الرمز الترويجي غير متاح في هذا الفرع") {
      setInvalid(true);

      logEvent(analytics, "coupon_code_failed", {
        phone: phone,
        code: codeRef.current.value,
      });
      /*
      window.webengage.track("Coupon Code Failed", {
        phone: phone,
        code:codeRef.current.value
      })*/

      setErrorMsgModel(true);
      setMsgAr("الرمز الترويجي غير متاح في هذا الفرع");
      setMsgEn("Promo code is not available in this branch");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else {
      setErrorMsgModel(true);
      setMsgAr(msg);
      setMsgEn(msg);
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    }
  }

  return (
    <div className="cartProcess">
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          {discount ? (
            <DiscountModal
              createOrder={createOrder}
              setLoading={setSubmitLoading}
              createBool={createBool}
              setModal={setDiscount}
            />
          ) : null}
          {freeMeal ? (
            <FreeMealModal
              createOrder={createOrder}
              setLoading={setSubmitLoading}
              createBool={createBool}
              setModal={setFreeMeal}
            />
          ) : null}

          <div className="container-fluid fluid ">
            <div className="cpMiddle-title2">
              <p> {t("process.details")}</p>
              <div className="cpPayment">
                <div className="row ">
                  <div
                    style={{ display: "block", position: "relative" }}
                    className="col-md-6 cpPayment-div2 "
                  >
                    <p>{t("process.payment_type")}</p>
                    {paymentMethod.wallet ? (
                      <div
                        className={
                          localStorage.getItem("paymentMethod") == "wallet"
                            ? "activeChoice"
                            : null
                        }
                        onClick={() => walletRef.current.click()}
                      >
                        <label>
                          {" "}
                          <IoWalletOutline
                            size={23}
                            className="labelIcon"
                          />{" "}
                          {t("process.wallet")}{" "}
                        </label>
                        <span>
                          ({wallet}{" "}
                          {i18n.language == "ar" ? currency.ar : currency.en} )
                        </span>
                        <input
                          ref={walletRef}
                          value="wallet"
                          id="wallet"
                          onChange={(e) => handleOptionChange(e, 1)}
                          type="radio"
                          name="payment"
                          defaultChecked={
                            localStorage.getItem("paymentMethod") == "wallet"
                              ? true
                              : false
                          }
                        />
                      </div>
                    ) : null}

                    {paymentMethod.cash &&
                    (parseInt(localStorage.getItem("typeId")) === 4 ||
                      parseInt(localStorage.getItem("typeId")) === 2) ? (
                      <div
                        className={
                          localStorage.getItem("paymentMethod") == "cash"
                            ? "activeChoice"
                            : null
                        }
                        onClick={() => cashRef.current.click()}
                      >
                        <label>
                          {" "}
                          <BsCash size={23} className="labelIcon" />
                          {t("process.cash")}
                        </label>

                        <input
                          value="cash"
                          ref={cashRef}
                          id="cash"
                          onChange={(e) => handleOptionChange(e, 2)}
                          type="radio"
                          name="payment"
                          defaultChecked={
                            localStorage.getItem("paymentMethod") == "cash"
                              ? true
                              : false
                          }
                        />
                      </div>
                    ) : null}
                    {paymentMethod.paymentOnline ? (
                      onlineType == "tap" ? (
                        <div
                          className={
                            localStorage.getItem("paymentMethod") == "tap"
                              ? "activeChoice"
                              : null
                          }
                          onClick={() => tapRef.current.click()}
                        >
                          <label>
                            <BsCreditCard size={23} className="labelIcon" />
                            {t("process.credit")}
                          </label>

                          <input
                            value="tap"
                            id="tap"
                            ref={tapRef}
                            onChange={(e) => handleOptionChange(e, 3)}
                            type="radio"
                            name="payment"
                            defaultChecked={
                              localStorage.getItem("paymentMethod") == "tap"
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className={
                            localStorage.getItem("paymentMethod") == "checkout"
                              ? "activeChoice"
                              : null
                          }
                          onClick={() => tapRef.current.click()}
                        >
                          <label>
                            <BsCreditCard size={23} className="labelIcon" />
                            {t("process.credit")}
                          </label>

                          <input
                            value="checkout"
                            id="tap"
                            ref={tapRef}
                            onChange={(e) => handleOptionChange(e, 3)}
                            type="radio"
                            name="payment"
                            defaultChecked={
                              localStorage.getItem("paymentMethod") ==
                              "checkout"
                                ? true
                                : false
                            }
                          />
                        </div>
                      )
                    ) : null}
                    {localStorage.getItem("typeId") !== "3" && (
                      <>
                        <p>{t("process.code")}</p>

                        <div className="promo">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              ref={codeRef}
                              className={errorMsgModel2 ? "invalid" : null}
                              value={code}
                              onChange={(e) => {
                                setCode(e.target.value);
                                localStorage.setItem("promo", e.target.value);
                              }}
                            />
                            {offer &&
                            localStorage.getItem("showIcn") == "true" ? (
                              <TiTick
                                size={30}
                                className={
                                  i18n.language == "ar" ? "tick" : "tickEn"
                                }
                              />
                            ) : null}
                          </div>
                          <button onClick={handleOffer}>
                            {t("profile.confirm")}
                          </button>
                        </div>
                      </>
                    )}

                    {i18n.language == "ar" ? (
                      <span style={{ fontSize: "14px", color: "brown" }}>
                        {errorMsgModel2 ? msgAr2 : null}
                      </span>
                    ) : (
                      <span style={{ fontSize: "14px", color: "brown" }}>
                        {errorMsgModel2 ? msgEn2 : null}
                      </span>
                    )}

                    <div
                      style={{ minHeight: "100px" }}
                      className={
                        errorMsgModel ? "addAlert " : " addAlert alert-hidden "
                      }
                    >
                      <span style={{ color: "brown" }}>
                        {i18n.language == "en" ? msgEn : msgAr}
                      </span>
                    </div>

                    <span></span>
                  </div>

                  <div className="col-md-6 cpPayment-div1">
                    <div style={{ padding: "1em" }}>
                      <p className="cpPayment-div1-title">
                        {t("process.payment_summary")}
                      </p>
                      <div className="cpMoneyBreakdown">
                        <div>
                          <p> {t("process.price")}</p>
                          <p className="cpMoney">
                            {breakdown.originalPrice}{" "}
                            {i18n.language == "ar"
                              ? cart.currencyAr
                              : cart.currencyEn}
                          </p>
                        </div>
                        <div>
                          <p>
                            {t("process.tax")} ({breakdown.tax}%)
                          </p>

                          <p className="cpMoney">
                            {breakdown.taxValue.toFixed(2)}{" "}
                            {i18n.language == "ar"
                              ? cart.currencyAr
                              : cart.currencyEn}
                          </p>
                        </div>
                        {cart.typeId == 2 ? (
                          <div>
                            <p> {t("process.delivery_charge")} </p>
                            <p className="cpMoney">
                              {breakdown.deliveryPrice}{" "}
                              {i18n.language == "ar"
                                ? cart.currencyAr
                                : cart.currencyEn}
                            </p>
                          </div>
                        ) : null}

                        {offer &&
                        (localStorage.getItem("offerType") == "discount" ||
                          localStorage.getItem("offerType") ==
                            "freeMealsDiscount" ||
                          localStorage.getItem("offerType") ==
                            "discountCashBack") ? (
                          <div>
                            <p> {t("process.discount")} </p>
                            <p className="cpMoney">
                              {parseFloat(
                                localStorage.getItem("discAmount")
                              ).toFixed(2)}{" "}
                              {i18n.language == "ar"
                                ? cart.currencyAr
                                : cart.currencyEn}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <hr />
                      <div style={{ transform: "none" }} className="cpTotaldiv">
                        <p className="cpTotalP"> {t("process.total")}</p>
                        <div>
                          <p className="cpTotal">
                            {offer &&
                            (localStorage.getItem("offerType") == "discount" ||
                              localStorage.getItem("offerType") ==
                                "freeMealsDiscount" ||
                              localStorage.getItem("offerType") ==
                                "discountCashBack")
                              ? localStorage.getItem("discountPrice")
                              : breakdown.totalPrice}
                          </p>
                          <p className="cpCurrency">
                            {" "}
                            {i18n.language == "ar"
                              ? cart.currencyAr
                              : cart.currencyEn}
                          </p>
                        </div>
                      </div>
                      {selectedOption == "checkout" ||
                      (selectedOption == "wallet" &&
                        (offer &&
                        (localStorage.getItem("offerType") == "discount" ||
                          localStorage.getItem("offerType") ==
                            "discountCashBack" ||
                          localStorage.getItem("offerType") ==
                            "freeMealsDiscount")
                          ? wallet < localStorage.getItem("discountPrice")
                          : wallet < breakdown.totalPrice) &&
                        onlineType == "checkout")
                        ? renderPayment()
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {selectedOption == "checkout" ||
            (selectedOption == "wallet" &&
              (offer &&
              (localStorage.getItem("offerType") == "discount" ||
                localStorage.getItem("offerType") == "discountCashBack" ||
                localStorage.getItem("offerType") == "freeMealsDiscount")
                ? wallet < localStorage.getItem("discountPrice")
                : wallet < breakdown.totalPrice) &&
              onlineType == "checkout") ? null : (
              <>
                {submitLoading ? (
                  <div
                    style={{ height: "23vh", alignItems: "unset" }}
                    className="loader"
                  >
                    <BeatLoader
                      color={"#E47100"}
                      loading={submitLoading}
                      size={30}
                    />
                  </div>
                ) : (
                  <div className="cartNext">
                    <button onClick={checkOffer}>
                      {" "}
                      {t("process.confirm_order")}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default CartProcess3;
