import React, { useEffect, useRef, useState } from 'react';
import '../Styles/mayser.css';
import {check_offer} from '../api'

const MoyasarPaymentForm = ({ amount, onSubmit, codeRef }) => {
  const formRef = useRef(null);
  const resolveRef = useRef(null);

  const [totalPriceAfterDiscount, setTotalPriceAferDiscount]= useState()

  useEffect(()=> {
    const detail = JSON.parse(localStorage.getItem("order_details")); 

    const data ={
      paymentMethod: localStorage.getItem("paymentMethod"),
      code: codeRef,
      notes: "",
      date: detail.date,
      now: detail.now.toString(),
      time: detail.time,
    }
    check_offer(data, localStorage.getItem("token")).then((res)=> {
      setTotalPriceAferDiscount(parseFloat(res.data.data.totalPrice))
      console.log(parseFloat(res.data.data.totalPrice))
    })
  },[] )

  useEffect(() => {
    // Load Moyasar script
    const moyasarScript = document.createElement('script');
    moyasarScript.src = 'https://cdn.moyasar.com/mpf/1.7.3/moyasar.js';
    moyasarScript.async = true;
    document.body.appendChild(moyasarScript);

    // Load Moyasar CSS
    const moyasarCss = document.createElement('link');
    moyasarCss.href = 'https://cdn.moyasar.com/mpf/1.7.3/moyasar.css';
    moyasarCss.rel = 'stylesheet';
    document.head.appendChild(moyasarCss);

    moyasarScript.onload = () => {
      if (window.Moyasar && totalPriceAfterDiscount  ) {
        
        window.Moyasar.init({
          element: '.mysr-form',
          amount:totalPriceAfterDiscount * 100 ,
          currency: 'SAR',
          description: 'test',
          publishable_api_key: 'pk_test_GvuFVt6MoXoYta6drR5dYakXRHP1veSFPqjFEPBh',
          callback_url: `https://alromansiah-new.bdaiat.com/payment-check-mayser`,
          methods: ['creditcard'],
          fixed_width: false,
          on_initiating: () => {
            return new Promise(async (resolve, reject) => {
              resolveRef.current = resolve; 
              try {
                await onSubmit(); 
                resolve(true); 
              } catch (error) {
                reject(error);
              }
            });
          },
        });

        // Attach any other event listeners after the form is initialized
        if (formRef.current) {
          formRef.current.addEventListener('submit', (event) => {
            event.preventDefault(); // Prevent default form submission
          });
        }
      }
    };

    return () => {
      document.body.removeChild(moyasarScript);
      document.head.removeChild(moyasarCss);
    };
  }, [amount, onSubmit, totalPriceAfterDiscount]);

  return (
    <section
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="mysr-form" ref={formRef}></div>
    </section>
  );
};

export default MoyasarPaymentForm;

